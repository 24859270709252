import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade'
import Observer from 'react-intersection-observer'
import AnchorLink from '../AnchorLink'

import Cross from '../SVG/Cross'
import ArrowDown from '../SVG/ArrowDown'

const TextAndImageItem = ({ index, item, items }) => {
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)

  let imageData = {}
  let imageMobileData = {}
  if (item.image != null) {
    imageData = {
      image: getImage(item.image.localFile),
      alt: item.image.alt,
    }
  }
  if (item.imageMobile != null) {
    imageMobileData = {
      image: getImage(item.imageMobile.localFile),
      alt: item.imageMobile.alt,
    }
  }

  return (
    <div
      className={`lg:flex${
        index !== items.length - 1 ? ' lg:-mb-32' : ''
      } slide`}
    >
      <div className="bg-white lg:w-1/2 lg:pb-20 lg:flex lg:items-center">
        <div className="mx-6 px-1/12 lg:px-1/24 lg:pr-0 lg:mr-0 lg:ml-10">
          <div className="lg:w-9/11 py-20 lg:pt-32 lg:pb-12">
            <Fade top distance="20px" delay={500}>
              <div className="space-y-6">
                {item.heading_primary && (
                  <h3 className="text-lg lg:text-2xl leading-none">
                    {item.heading_primary}
                  </h3>
                )}
                <div
                  className="rte"
                  dangerouslySetInnerHTML={{
                    __html: item.body_primary.html,
                  }}
                />
                {item.body_secondary.text !== '' && (
                  <button
                    className="link-opacity focus:outline-none font-medium leading-none flex items-center w-full"
                    onClick={() => {
                      setReadMoreIsOpen(!readMoreIsOpen)
                    }}
                  >
                    {item.read_more_heading
                      ? item.read_more_heading
                      : readMoreIsOpen
                      ? 'Read Less'
                      : 'Read More'}
                    <Cross
                      className={`w-4 h-4 ml-5 cross mb-1${
                        readMoreIsOpen ? ' cross--rotate' : ''
                      }`}
                    />
                  </button>
                )}
                {readMoreIsOpen && item.body_secondary.html && (
                  <div
                    className="rte"
                    dangerouslySetInnerHTML={{
                      __html: item.body_secondary.html,
                    }}
                  />
                )}
              </div>
            </Fade>
          </div>
          <AnchorLink
            href={index !== items.length - 1 ? `#slide-${index + 2}` : '#cta'}
            className="hidden -m-2 p-2 lg:inline-block z-0"
          >
            <Fade top distance="20px" delay={750}>
              <ArrowDown />
            </Fade>
          </AnchorLink>
        </div>
      </div>
      <div className={`lg:w-1/2`}>
        <Fade ssrReveal delay={250}>
          <div className="mx-6 lg:mr-10 py-14 lg:py-0 lg:px-1/6 h-full lg:flex xl:items-center lg:justify-center">
            <div className="hidden py-32 slide-image lg:flex justify-center sticky top-0 left-0 xl:static">
              <GatsbyImage
                image={imageData.image}
                alt={imageData.alt}
                loading="lazy"
              />
            </div>
            <div className="lg:hidden mx-1/12 lg:mx-1/24">
              <GatsbyImage
                className="w-full"
                image={imageMobileData.image}
                alt={imageMobileData.alt}
                loading="lazy"
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

const FlexibleContentTextAndImages = ({ slice, index }) => {
  const { items, primary } = slice

  const [activeSlide, setActiveSlide] = useState(null)
  const [bgColour, setBgColour] = useState(items[0].background_colour)

  const sectionId = primary.section_id
    ? primary.section_id
    : `text-and-images-${index}`

  return (
    <div id={sectionId}>
      <div className="sticker">
        <div className="sticker__inner">
          <div className="sticker__item sticker__item--dark">
            <Fade ssrReveal delay={250}>
              <div className="flex flex-col w-full">
                {items.map((item, index) => {
                  return (
                    <AnchorLink
                      className={`sticker__link ${
                        activeSlide === index ? 'sticker__link--active' : ''
                      }`}
                      key={`${index}-${item.background_colour}`}
                      href={`#slide-${index + 1}`}
                    />
                  )
                })}
              </div>
            </Fade>
          </div>
        </div>

        <div
          className="transition-colors duration-1000 ease-linear"
          style={{ background: bgColour }}
        >
          {items.map((item, index) => {
            return (
              <Observer
                id={`slide-${index + 1}`}
                as="div"
                key={index}
                onChange={inView => {
                  if (inView === true) {
                    setActiveSlide(index)
                    setBgColour(item.background_colour)
                  }
                }}
                threshold={0.5}
              >
                <TextAndImageItem index={index} item={item} items={items} />
              </Observer>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FlexibleContentTextAndImages
