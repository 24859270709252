import React from 'react'

function FacebookIcon() {
  return (
    <svg
      className="h-full w-full"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 15.042c0 3.5-2.569 6.408-5.927 6.916v-4.883h1.637l.31-2.033h-1.947v-1.298c0-.564.282-1.1 1.157-1.1h.875V10.92s-.79-.14-1.58-.14c-1.581 0-2.626.987-2.626 2.737v1.524h-1.778v2.033h1.778v4.883C10.54 21.45 8 18.542 8 15.042c0-3.867 3.133-7 7-7s7 3.133 7 7Z"
        fill="#092A33"
      />
      <path stroke="#092A33" d="M.5.5h29v29H.5z" />
    </svg>
  )
}

export default FacebookIcon
