import React from 'react'

function EmailIcon() {
  return (
    <svg
      className="h-full w-full"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16.395 7.626 6.023 4.096c.212.145.319.217.396.314a.85.85 0 0 1 .15.29c.036.12.036.253.036.52v6.136c0 1.407 0 2.11-.262 2.647-.23.472-.597.856-1.048 1.097-.514.274-1.186.274-2.53.274h-8.32c-1.344 0-2.016 0-2.53-.274a2.459 2.459 0 0 1-1.048-1.097C7 21.092 7 20.389 7 18.982v-6.137c0-.266 0-.398.035-.519a.85.85 0 0 1 .151-.29c.077-.097.184-.17.396-.314l6.023-4.096m2.79 0c-.505-.343-.757-.515-1.03-.582a1.53 1.53 0 0 0-.73 0c-.273.067-.525.239-1.03.582m2.79 0 4.954 3.37c.55.374.825.56.92.798a.873.873 0 0 1 0 .649c-.095.237-.37.424-.92.798l-4.954 3.37c-.505.343-.757.514-1.03.581-.24.06-.49.06-.73 0-.273-.067-.525-.238-1.03-.582L8.65 13.241c-.55-.374-.825-.561-.92-.798a.873.873 0 0 1 0-.649c.095-.237.37-.424.92-.799l4.954-3.369m8.995 13.7-5.314-5.022m-4.572 0L7.4 21.326"
        stroke="#092A33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path stroke="#092A33" d="M.5.5h29v29H.5z" />
    </svg>
  )
}

export default EmailIcon
