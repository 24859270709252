import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade'
import RightChevron from '../SVG/RightChevron'

function FurtherReadings({ blog, currentArticleUid, currentArticleCategory }) {
  let filteredArticles = blog.article.filter(article => {
    const passesFilter =
      article?.article_link?.uid !== currentArticleUid &&
      article?.article_link?.document?.data?.categories.some(
        category => category.category_name === currentArticleCategory
      )
    return passesFilter
  })

  if (filteredArticles.length === 0) {
    filteredArticles = blog.article
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 3)
  }

  return (
    <div className=" bg-green text-white mx-auto max-w-7xl py-20">
      {blog.article_block_heading && (
        <h2 className="text-4xl md:text-6xl text-center text-cream mb-22">
          {blog.article_block_heading}
        </h2>
      )}

      {filteredArticles.map((article, index) => {
        let item = article?.article_link?.document?.data
        let uid = article?.article_link?.uid
        return (
          <Fade key={index} ssrReveal delay={500 + 250 * (index + 1)}>
            <div>
              <div
                className={`pt-8 pb-6${
                  index === blog.article.length - 1
                    ? ''
                    : ' border-b border-cream'
                }`}
              >
                <div className="px-6 md:px-10 xl:px-0 lg:flex justify-between items-center">
                  {item.heading && (
                    <Link
                      className="inline-block lg:mr-8 hover:opacity-100 focus:opacity-100 link-group"
                      to={
                        item.category === 'Blog post'
                          ? `/blog/${uid}/`
                          : `/newsroom/${uid}/`
                      }
                    >
                      <div className="md:flex md:gap-x-8 items-center">
                        {item.image && (
                          <div className="h-full w-full md:w-56 flex-shrink-0">
                            <GatsbyImage
                              image={getImage(item.image.localFile)}
                              alt={item.image.alt}
                            />
                          </div>
                        )}
                        <div className="flex flex-col">
                          <h5 className="inline-block text-2xl leading-snug font-bold pt-8 md:pt-0 pb-3">
                            {item.heading}
                          </h5>
                          {item.summary.text !== '' && (
                            <p className="mt-4 text-base">
                              {item.summary.text}
                            </p>
                          )}
                          <div className="flex link-svg link-group__target-link mt-8">
                            <div className="mr-4 leading-tight pt-px">
                              Read more
                            </div>
                            <RightChevron />
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        )
      })}
    </div>
  )
}

export default FurtherReadings
