/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import 'gatsby-plugin-prismic-previews/dist/styles.css'
import { linkResolver } from './src/utils/linkResolver'

import Pages from './src/templates/pages'
import Newsroom from './src/pages/newsroom'
import NewsArticle from './src/templates/newsArticle'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          pages: Pages,
          newsroom: Newsroom,
          newsArticle: NewsArticle,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
