import React from 'react'
import Fade from 'react-reveal/Fade'

import { getBackgroundClass, getTextClass } from '../../utils/classes'

const FlexibleContentLeadText = ({ slice, index }) => {
  const { primary } = slice
  const { background_colour, text_colour } = slice.primary
  const sectionId = primary.section_id
    ? primary.section_id
    : `lead-text-${index}`

  return (
    <div
      id={sectionId}
      className={`${getBackgroundClass(background_colour)} ${getTextClass(
        text_colour
      )}`}
    >
      <div className="mx-6 lg:mx-10 lg:px-1/24">
        <div className="pb-48 lg:pb-60">
          <div id="main" className="pt-24 lg:w-1/2 pr-1/12 lg:pr-1/24">
            <Fade ssrReveal delay={250}>
              <div>
                {primary.heading_primary && (
                  <div className="mb-6">
                    <h3 className="text-3xl lg:text-5xl leading-none">
                      {primary.heading_primary}
                    </h3>
                  </div>
                )}
                {primary.body_primary.html && (
                  <div
                    className="rte lg:text-lg"
                    dangerouslySetInnerHTML={{
                      __html: primary.body_primary.html,
                    }}
                  />
                )}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlexibleContentLeadText
