export const getBackgroundClass = colour => {
  switch (colour) {
    case 'White':
      return 'bg-white'
    case 'Pink':
      return 'bg-pink'
    case 'Cream':
      return 'bg-cream'
    case 'Brown':
      return 'bg-brown'
    case 'Green':
      return 'bg-green'
    default:
      return 'bg-cream'
  }
}

export const getTextClass = colour => {
  switch (colour) {
    case 'Light':
      return 'text-cream'
    case 'Dark':
      return 'text-green'
    default:
      return 'text-green'
  }
}
export const getBorderClass = colour => {
  switch (colour) {
    case 'Light':
      return 'border-cream'
    case 'Dark':
      return 'border-green'
    default:
      return 'border-green'
  }
}
