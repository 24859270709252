import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

import { getBackgroundClass, getTextClass } from '../../utils/classes'

import ArrowRight from '../SVG/ArrowRight'

const FlexibleContentHeadingAndBody = ({ slice, index }) => {
  const { items, primary } = slice

  const getPaddingTopClass = () => {
    switch (primary.padding_top) {
      case 'None':
        return 'pt-14 lg:pt-24'
      case 'Default':
        return 'pt-14 lg:pt-24'
      default:
        return ''
    }
  }

  const sectionId = primary.section_id
    ? primary.section_id
    : `heading-and-body-${index}`

  return (
    <div
      id={sectionId}
      className={`${getBackgroundClass(
        primary.background_colour
      )} ${getTextClass(primary.text_colour)} ${getPaddingTopClass()}`}
    >
      <div className="mx-6 lg:mx-10 lg:px-1/24">
        <div
          id="main"
          className={`-mx-6 lg:mx-0 ${
            items.length > 1 ? 'lg:pb-16 ' : 'pb-14 lg:pb-24'
          }`}
        >
          {items.map((item, index) => {
            return (
              <Fade key={index} ssrReveal delay={500 * (index + 1)}>
                <div
                  className={`lg:flex px-6 lg:px-0 ${
                    items.length > 1
                      ? 'border-t pt-6 lg:pt-8 pb-10 lg:pb-24'
                      : ''
                  }`}
                >
                  <div className="lg:w-1/2 mb-6 lg:mb-0">
                    {item.heading_primary && (
                      <h4 className="text-3xl lg:text-5xl leading-none">
                        {item.heading_primary}
                      </h4>
                    )}
                  </div>
                  <div className="lg:w-1/2 lg:text-lg">
                    {item.body_primary.html && (
                      <div
                        className="rte"
                        dangerouslySetInnerHTML={{
                          __html: item.body_primary.html,
                        }}
                      />
                    )}
                    {(item.link.url || item.link.uid) && (
                      <div className="mt-6">
                        {item.link.link_type === 'Web' ? (
                          <a
                            className="text-base link-svg flex items-center flex-shrink-0 group-hover:opacity-50"
                            href={item.link.url}
                            target={item.link.target}
                            rel="noopener noreferrer"
                          >
                            <span>
                              {item.link_heading
                                ? item.link_heading
                                : 'More Info'}
                            </span>
                            <ArrowRight className="ml-6 w-5 stroke-current" />
                          </a>
                        ) : (
                          <Link
                            className=" text-base link-svg flex items-center flex-shrink-0 group-hover:opacity-50"
                            to={`/${item.link.uid}/`}
                          >
                            <span>
                              {item.link_heading
                                ? item.link_heading
                                : 'More Info'}
                            </span>
                            <ArrowRight className="ml-6 w-5 stroke-current" />
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Fade>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FlexibleContentHeadingAndBody
