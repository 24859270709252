import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { SliceZone } from '@prismicio/react'
import { CSSTransition } from 'react-transition-group'

import AnchorLink from '../AnchorLink'

const SubmenuAnchorLink = ({ slice, context }) => {
  const onClick = () => {
    if (context.closeMobileMenu) {
      context.closeMobileMenu()
    }
    return
  }

  if (context.link === context.location.pathname.replace('/', '')) {
    return (
      <AnchorLink
        href={`#${slice.primary.anchor_id}`}
        offset={0}
        onClick={() => onClick()}
        className={`submenu__item ${
          context.className ? context.className : ''
        }`}
      >
        {slice.primary.link_heading}
      </AnchorLink>
    )
  } else {
    return (
      <AniLink
        className={`submenu__item ${
          context.className ? context.className : ''
        }`}
        fade
        to={`/${context.link}/#${slice.primary.anchor_id}`}
        onClick={context.resumeScroll}
        duration={1}
      >
        <div>{slice.primary.link_heading}</div>
      </AniLink>
    )
  }
}

const SubmenuPageLink = ({ slice, context }) => {
  return (
    <AniLink
      className={`submenu__item ${context.className ? context.className : ''}`}
      fade
      to={`/${slice.primary.link_primary.uid}`}
      onClick={context.resumeScroll}
      duration={1}
    >
      <div>{slice.primary.link_heading}</div>
    </AniLink>
  )
}

const Submenu = ({
  item,
  showSubmenu,
  bgColor,
  borderColor,
  divideColor,
  location,
  closeMobileMenu,
  className,
}) => {
  const resumeScroll = () => {
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.remove('no-scroll')
  }

  return (
    <CSSTransition
      in={showSubmenu}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <div
        className={`submenu lg:divide-y ${divideColor ? divideColor : ''} ${
          bgColor ? bgColor : ''
        } ${borderColor ? borderColor : ''}`}
      >
        <SliceZone
          slices={item.body}
          components={{
            anchor_link: SubmenuAnchorLink,
            page_link: SubmenuPageLink,
          }}
          context={{
            link: item.link.uid,
            location: location,
            resumeScroll: resumeScroll,
            closeMobileMenu: closeMobileMenu,
            className: className,
          }}
        />
      </div>
    </CSSTransition>
  )
}

export default Submenu
