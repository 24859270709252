import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import Menu from '../Menu'
import MobileMenu from '../MobileMenu'
import Logo from '../SVG/Logo'

const Header = ({
  toggleMobileMenu,
  closeMobileMenu,
  resumeScroll,
  mobileMenuIsOpen,
  location,
  menuItems,
  headerTheme,
}) => {
  let bgColor
  let borderColor
  let divideColor
  let hamburgerColor
  if (headerTheme === 'Brown') {
    bgColor = 'bg-brown text-cream'
    divideColor = 'divide-cream-50'
    borderColor = 'border-cream-50'
    hamburgerColor = 'bg-cream'
  } else if (headerTheme === 'Green') {
    bgColor = 'bg-green text-cream'
    divideColor = 'divide-cream-50'
    borderColor = 'border-cream-50'
    hamburgerColor = 'bg-cream'
  } else {
    bgColor = 'bg-cream'
    divideColor = 'divide-green-50'
    borderColor = 'border-green-50'
    hamburgerColor = 'bg-green'
  }

  return (
    <header className={bgColor}>
      <Fade ssrReveal>
        <div className={`header ${mobileMenuIsOpen ? 'bg-green' : ''}`}>
          <div className={`w-1/5 lg:flex justify-center items-center`}>
            <div className="lg:-ml-3 w-32 lg:w-36 lg:pt-5">
              {location.pathname !== '/' ? (
                <AniLink onClick={resumeScroll} fade to="/">
                  <Logo
                    className={`fill-current w-full h-full transition-colors duration-300 ease-linear ${
                      mobileMenuIsOpen ? 'text-cream' : ''
                    }`}
                    mobileMenuIsOpen={mobileMenuIsOpen}
                  />
                </AniLink>
              ) : (
                <AniLink fade to="/" aria-label="Leaft Foods Ltd">
                  <Logo
                    className={`fill-current w-full h-full ${
                      mobileMenuIsOpen ? 'text-cream' : ''
                    }`}
                    mobileMenuIsOpen={mobileMenuIsOpen}
                  />
                </AniLink>
              )}
            </div>
          </div>
          <Menu
            divideColor={divideColor}
            borderColor={borderColor}
            bgColor={bgColor}
            headerTheme={headerTheme}
            location={location}
            className="hidden lg:flex w-4/5"
            menuItems={menuItems}
          />
          <div
            className={`lg:hidden hamburger${
              mobileMenuIsOpen === true ? ' hamburger--is-open' : ''
            }`}
            onClick={() => {
              toggleMobileMenu()
            }}
          >
            <div
              className={`hamburger__line hamburger__line--primary ${hamburgerColor}`}
            />
            <div
              className={`hamburger__line hamburger__line--secondary ${hamburgerColor}`}
            />
            <div
              className={`hamburger__line hamburger__line--tertiary ${hamburgerColor}`}
            />
          </div>
        </div>
      </Fade>
      <div
        className={`mobile-menu${
          mobileMenuIsOpen === true ? ' mobile-menu--is-open' : ''
        }`}
      >
        <MobileMenu
          resumeScroll={resumeScroll}
          menuItems={menuItems}
          location={location}
          closeMobileMenu={closeMobileMenu}
        />
      </div>
    </header>
  )
}

export default Header
