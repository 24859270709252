import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import ClockIcon from '../components/SVG/ClockIcon'
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import ArrowRight from '../components/SVG/ArrowRight'
import CallToAction from '../components/CallToAction'
import FurtherReadings from '../components/Blog/furtherReadings'
import CopyIcon from '../components/SVG/CopyIcon'
import EmailIcon from '../components/SVG/EmailIcon'
import TwitterIcon from '../components/SVG/TwitterIcon'
import LinkedinIcon from '../components/SVG/LinkedinIcon'
import FacebookIcon from '../components/SVG/FacebookIcon'
import Observer from 'react-intersection-observer'
import Fade from 'react-reveal/Fade'

const NewsArticle = ({ data, location }) => {
  const page = data.allPrismicNewsArticle.nodes[0].data
  const blog = data.prismicBlogIndex.data
  let authorData = null
  if (data.allPrismicNewsArticle.nodes[0].data.article_author.document) {
    authorData =
      data.allPrismicNewsArticle.nodes[0].data.article_author.document.data
  }

  const [activeSection, setActiveSection] = useState(0)

  let imageData = {}
  if (page.image && page.image.localFile) {
    imageData = {
      image: getImage(page.image.localFile),
      alt: page.image.alt ? page.image.alt : page.heading,
    }
  }

  let authorImage = {}
  if (
    authorData &&
    authorData.author_image &&
    authorData.author_image.localFile &&
    authorData.author_image.localFile.childImageSharp &&
    authorData.author_image.localFile.childImageSharp.gatsbyImageData
  ) {
    authorImage = {
      image: getImage(
        authorData.author_image.localFile.childImageSharp.gatsbyImageData
      ),
      alt: authorData.author_image.alt
        ? authorData.author_image.alt
        : authorData.name,
    }
  }

  let articleBody = ''
  if (page.sections) {
    articleBody = page.sections
      .map(section => {
        let sectionText = ''
        if (section.section_heading) {
          sectionText += section.section_heading + ' '
        }
        if (section.section_subheading) {
          sectionText += section.section_subheading + ' '
        }
        if (section.section_body.text) {
          sectionText += section.section_body.text
        }
        return sectionText
      })
      .join(' ')
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: page.heading,
    datePublished: page.post_date,
    dateModified: page.post_date,
    author: {
      '@type': 'Person',
      name: authorData ? authorData.name : '',
    },
    image: {
      '@type': 'ImageObject',
      url: page.image?.url,
      height: 600,
      width: 800,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Publisher Name',
      logo: {
        '@type': 'ImageObject',
        url: authorImage.image?.url,
      },
    },
    articleBody: articleBody,
  }

  return (
    <Layout location={location}>
      <Seo
        title={page.meta_title && page.meta_title}
        image={page.image?.url && page.image.url}
        description={page.meta_description && page.meta_description}
        canonicalUrl={page.canonical_url?.url && page.canonical_url.url}
        location={location}
        structuredData={structuredData}
      />
      <div>
        <div className="fullscreen__inner flex items-center">
          <div className="w-full mx-6 lg:mx-10 lg:px-1/24">
            <Link
              to="/blog/"
              className="inline-flex items-center lg:hidden link-svg link-svg--left mb-4"
            >
              <span className="mr-2 mb-1">
                <ArrowRight className="stroke-current h-2" />
              </span>
              Return to Blog
            </Link>
            <div className="lg:flex justify-between">
              {Fade && (
                <Fade ssrReveal delay={500}>
                  <div className="w-full lg:w-1/2 lg:order-last pb-14 lg:pb-0">
                    <GatsbyImage
                      image={imageData.image}
                      alt={imageData.alt}
                      loading="lazy"
                    />
                  </div>
                </Fade>
              )}
              {Fade && (
                <Fade ssrReveal delay={500}>
                  <div className="lg:w-1/2 lg:order-1 lg:flex flex-col justify-center">
                    <Link
                      to="/blog/"
                      className="hidden lg:inline-flex lg:items-center link-svg link-svg--left"
                    >
                      <span className="mr-2 mb-1">
                        <ArrowRight className="stroke-current h-2" />
                      </span>
                      Return to Blog
                    </Link>
                    <div className="flex-1 flex flex-col justify-center">
                      <div className="mb-5 lg:mb-10 lg:pr-1/12 relative z-20">
                        {page.heading && (
                          <h1 className="text-4xl lg:text-6xl leading-none mb-5 lg:mb-10">
                            {page.heading}
                          </h1>
                        )}
                        {page.summary.text !== null && (
                          <div
                            className="rte lg:text-lg xl:text-2xl font-medium"
                            dangerouslySetInnerHTML={{
                              __html: page.summary.html,
                            }}
                          />
                        )}
                        {authorData && (
                          <div className="flex items-center mt-10">
                            {authorImage.image && (
                              <GatsbyImage
                                className="rounded-full w-10 h-10 mr-3"
                                image={authorImage.image}
                                alt={authorImage.alt}
                              />
                            )}
                            {authorData.name && (
                              <p className="text-green text-sm font-bold">
                                {authorData.name}
                              </p>
                            )}
                          </div>
                        )}
                        {page.post_date && (
                          <div className="flex gap-x-2 mt-6 md:mt-16 items-center font-bold text-xs text-green">
                            {page.post_date && <div>{page.post_date}</div>}
                            {page.read_time && (
                              <div className="flex items-center ml-8">
                                <div className="h-4 w-4">
                                  <ClockIcon />
                                </div>
                                <p className="pl-2">{page.read_time}min read</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </div>
        <main id="main">
          <div className="mx-6 lg:mx-10 lg:px-1/24 mt-12 md:mt-30">
            <div className="pb-48 lg:pb-60">
              <div className="md:grid grid-cols-12 col-gap-24 relative">
                <div className="hidden md:block md:col-span-3 border-t border-green sticky-section h-56">
                  <h4 className="uppercase pt-2 font-extrabold text-xs mb-10">
                    Table of Contents
                  </h4>
                  {page.sections &&
                    page.sections.map(
                      (item, index) =>
                        item && (
                          <div className="w-full">
                            {item.section_label && (
                              <a
                                href={`#${item.section_label}`}
                                className={`pl-2 font-medium py-3 block ${
                                  activeSection === index ? 'bg-pink' : ''
                                }`}
                              >
                                {item.section_label}
                              </a>
                            )}
                          </div>
                        )
                    )}
                  <div className="mt-12">
                    <p className="text-xs">Share</p>
                    <div className="flex mt-3 space-x-2 h-8">
                      <a
                        className="w-8 cursor-pointer"
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          navigator.clipboard.writeText(location.href)
                          return false
                        }}
                      >
                        <CopyIcon />
                      </a>
                      <a
                        className="w-8"
                        href={`mailto:?body=${encodeURIComponent(
                          location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <EmailIcon />
                      </a>
                      <a
                        className="w-8"
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                          location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TwitterIcon />
                      </a>
                      <a
                        className="w-8"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                          location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedinIcon />
                      </a>
                      <a
                        className="w-8"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          location.href
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FacebookIcon />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  id="main"
                  className="col-span-5 lg:col-span-7 xl:col-span-9"
                >
                  <div className="flex gap-x-2">
                    {page.categories.map(category => {
                      return (
                        <div className="border uppercase text-xs text-pink px-3 py-1 inline-block border-pink rounded-full">
                          {category.category_name}
                        </div>
                      )
                    })}
                  </div>
                  <div className="mt-4 space-y-12">
                    {page.sections.map((item, index) => (
                      <Observer
                        threshold={0.5}
                        key={index}
                        onChange={inView => {
                          if (inView) {
                            setActiveSection(index)
                          }
                        }}
                      >
                        {Fade && (
                          <Fade ssrReveal delay={250}>
                            <div id={item.section_label}>
                              {item.section_heading && (
                                <h2 className="text-3xl md:text-4xl leading-tightest lg:text-5xl font-medium">
                                  {item.section_heading}
                                </h2>
                              )}
                              {item.section_subheading && (
                                <h3 className="pt-5 text-2xl font-medium">
                                  {item.section_subheading}
                                </h3>
                              )}
                              {item.section_body.html && (
                                <div
                                  className="rte lg:text-lg pt-4"
                                  dangerouslySetInnerHTML={{
                                    __html: item.section_body.html,
                                  }}
                                />
                              )}
                            </div>
                          </Fade>
                        )}
                      </Observer>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="bg-green">
          <FurtherReadings
            blog={blog}
            currentArticleUid={data.allPrismicNewsArticle.nodes[0].uid}
            currentArticleCategory={page.categories[0].category_name}
          />
        </div>
        <CallToAction />
      </div>
    </Layout>
  )
}

export const newsArticleQuery = graphql`
  query($uid: String) {
    allPrismicNewsArticle(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        _previewable
        data {
          category
          heading
          meta_description
          meta_title
          canonical_url {
            url
          }
          post_date(formatString: "DD MMMM YYYY")
          summary {
            html
            text
          }
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1)
              }
            }
          }
          body {
            html
            text
          }
          article_author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  author_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1)
                      }
                    }
                  }
                  name
                }
              }
            }
          }
          read_time
          categories {
            category_name
          }
          sections {
            section_label
            section_heading
            section_subheading
            section_body {
              html
            }
          }
        }
      }
    }
    prismicBlogIndex {
      data {
        article_block_heading
        articles_heading
        subtitle
        title
        article {
          article_link {
            uid
            url
            document {
              ... on PrismicNewsArticle {
                id
                data {
                  summary {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1)
                      }
                    }
                  }
                  heading
                  categories {
                    category_name
                  }
                  article_author {
                    document {
                      ... on PrismicAuthor {
                        id
                        data {
                          name
                          author_image {
                            alt
                            localFile {
                              childImageSharp {
                                gatsbyImageData(aspectRatio: 2)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(NewsArticle)
