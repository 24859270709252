import React from 'react'

function CopyIcon() {
  return (
    <svg
      className="h-full w-full"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 12.833V8.5m0 0h-4.333m4.333 0-5.778 5.778m-2.166-4.334h-1.59c-1.213 0-1.82 0-2.283.237-.408.207-.74.539-.947.947-.236.463-.236 1.07-.236 2.283v4.622c0 1.214 0 1.82.236 2.284.208.408.54.74.947.947.463.236 1.07.236 2.284.236h4.622c1.213 0 1.82 0 2.284-.236.407-.208.739-.54.946-.947.237-.463.237-1.07.237-2.284v-1.589"
        stroke="#092A33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path stroke="#092A33" d="M.5.5h29v29H.5z" />
    </svg>
  )
}

export default CopyIcon
