import React, { Component } from 'react'
import Lottie from 'react-lottie'

class Animation extends Component {
  render() {
    const {
      animationData,
      animationInView,
      animationHasPlayed,
      className,
      text,
    } = this.props

    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    return (
      <div
        className={`${
          animationHasPlayed === true || animationInView
            ? 'opacity-100'
            : 'opacity-0'
        } transition-opacity duration-300 ease-linear`}
      >
        <div className={className && className + ' pointer-events-none'}>
          <Lottie
            options={defaultOptions}
            isStopped={animationHasPlayed === false && !animationInView}
          />
        </div>
        {text && text}
      </div>
    )
  }
}

export default Animation
