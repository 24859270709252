import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Observer from 'react-intersection-observer'
import Animation from '../Animation'
import animationOne from '../../json/ctaOne.json'
import animationTwo from '../../json/ctaTwo.json'
import animationThree from '../../json/ctaThree.json'

class CallToAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      animationOneHasPlayed: false,
      animationOneInView: false,
      animationTwoHasPlayed: false,
      animationTwoInView: false,
      animationThreeHasPlayed: false,
      animationThreeInView: false,
    }
  }

  render() {
    const { customTitle } = this.props
    const MediaPageLink = 'newsroom'
    const AboutPageLink = 'about'
    const JoinPageLink = 'join'
    return (
      <StaticQuery
        query={graphql`
          query CtaQuery {
            prismicGlobal {
              data {
                links_heading
              }
            }
          }
        `}
        render={data => (
          <div
            id="cta"
            className="px-6 lg:px-10 bg-cream pt-30 pb-12 lg:py-36 text-center"
          >
            <div className="lg:px-1/24">
              <Fade ssrReveal delay={250}>
                {customTitle && customTitle ? (
                  <h4 className="text-lg mb-16 font-medium">{customTitle}</h4>
                ) : (
                  data.prismicGlobal.data.links_heading && (
                    <h4 className="text-lg lg:text-2xl mb-16 font-medium">
                      {data.prismicGlobal.data.links_heading}
                    </h4>
                  )
                )}
              </Fade>
              <Fade ssrReveal delay={500}>
                <div className="flex flex-col lg:flex-row items-center lg:justify-center">
                  <div className="w-1/2 lg:w-6/22 py-12 lg:py-10 border-b lg:border-b-0 lg:border-r border-green-50">
                    <div className="flex flex-col items-center">
                      <Link
                        className="text-lg text-center font-medium"
                        to={`/${MediaPageLink}/`}
                      >
                        <Observer
                          as="div"
                          threshold={0}
                          onChange={inView => {
                            if (inView === true) {
                              setTimeout(() => {
                                this.setState({
                                  animationOneInView: true,
                                  animationOneHasPlayed: true,
                                })
                              }, 750)
                            } else {
                              this.setState({
                                animationOneInView: false,
                              })
                            }
                          }}
                        >
                          <Animation
                            className="mb-6 mx-auto w-18 h-18"
                            animationData={animationOne}
                            animationInView={this.state.animationOneInView}
                            animationHasPlayed={
                              this.state.animationOneHasPlayed
                            }
                            text="Newsroom"
                          />
                        </Observer>
                      </Link>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-6/22 py-12 lg:py-10 border-b lg:border-b-0 lg:border-r border-green-50">
                    <div className="flex flex-col items-center">
                      <Link
                        className="text-lg text-center font-medium"
                        to={`/${AboutPageLink}/`}
                      >
                        <Observer
                          as="div"
                          threshold={0}
                          onChange={inView => {
                            if (inView === true) {
                              setTimeout(() => {
                                this.setState({
                                  animationTwoInView: true,
                                  animationTwoHasPlayed: true,
                                })
                              }, 1250)
                            } else {
                              this.setState({
                                animationTwoInView: false,
                              })
                            }
                          }}
                        >
                          <Animation
                            className="mb-6 mx-auto w-18 h-18"
                            animationData={animationTwo}
                            animationInView={this.state.animationTwoInView}
                            animationHasPlayed={
                              this.state.animationTwoHasPlayed
                            }
                            text="About Us"
                          />
                        </Observer>
                      </Link>
                    </div>
                  </div>
                  <div className="w-1/2 lg:w-6/22 py-12 lg:py-10">
                    <div className="flex flex-col items-center">
                      <Link
                        className="text-lg text-center font-medium"
                        to={`/${JoinPageLink}/`}
                      >
                        <Observer
                          as="div"
                          threshold={0}
                          onChange={inView => {
                            if (inView === true) {
                              setTimeout(() => {
                                this.setState({
                                  animationThreeInView: true,
                                  animationThreeHasPlayed: true,
                                })
                              }, 1750)
                            } else {
                              this.setState({
                                animationThreeInView: false,
                              })
                            }
                          }}
                        >
                          <Animation
                            className="mb-6 mx-auto w-18 h-18"
                            animationData={animationThree}
                            animationInView={this.state.animationThreeInView}
                            animationHasPlayed={
                              this.state.animationThreeHasPlayed
                            }
                            text="Join Us"
                          />
                        </Observer>
                      </Link>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        )}
      />
    )
  }
}

export default CallToAction
