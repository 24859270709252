import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/Layout'
import Seo from '../components/SEO'
import FlexibleContent from '../components/FlexibleContent'
import CallToAction from '../components/CallToAction'
import Hero from '../components/Hero'

const PagesTemplate = ({ data, location }) => {
  const page = data.allPrismicPages.nodes[0].data
  const uid = data.allPrismicPages.nodes[0].uid

  return (
    <Layout location={location} headerTheme={page.header_background}>
      <Seo
        headerTheme={page.header_background}
        title={page.meta_title && page.meta_title}
        image={page.site_image.url && page.site_image.url}
        description={page.meta_description && page.meta_description}
        canonicalUrl={page.canonical_url.url && page.canonical_url.url}
        location={location}
      />
      <Hero
        data={page}
        headerTheme={page.header_background}
        location={location}
      />
      {page.body && page.body.length > 0 && (
        <main id="main">
          <FlexibleContent data={page.body} />
        </main>
      )}
      {uid !== 'homepage' && <CallToAction />}
    </Layout>
  )
}

export const pagesQuery = graphql`
  query($uid: String) {
    allPrismicPages(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        _previewable
        data {
          heading
          meta_description
          canonical_url {
            url
          }
          site_image {
            url
          }
          meta_title
          subheading
          header_background
          images {
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1)
                }
              }
            }
          }
          button_text
          button_link {
            url
          }
          lead {
            html
          }
          body {
            ... on PrismicPagesDataBodyPartners {
              slice_type
              items {
                logo {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              primary {
                section_id
                background_colour
                body_primary {
                  html
                }
                heading_primary
                text_colour
              }
            }
            ... on PrismicPagesDataBodyTextWithExternalLinks {
              id
              slice_type
              items {
                link_text
                link_heading
                link {
                  uid
                  link_type
                  url
                  target
                }
              }
              primary {
                section_id
                background_colour
                text_colour
                no_links_message
                lead_primary {
                  html
                }
                heading_secondary
                heading_primary
                body_primary {
                  html
                }
              }
            }
            ... on PrismicPagesDataBodyHeadingAndBody {
              id
              slice_type
              primary {
                section_id
                background_colour
                text_colour
                padding_top
              }
              items {
                body_primary {
                  html
                }
                heading_primary
                link {
                  uid
                  link_type
                  url
                  target
                }
                link_heading
              }
            }
            ... on PrismicPagesDataBodyBody {
              id
              slice_type
              primary {
                section_id
                body_primary {
                  html
                }
                background_colour
                text_colour
              }
            }
            ... on PrismicPagesDataBodyImageAndAccordion {
              id
              slice_type
              items {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 1, width: 1024)
                    }
                  }
                }
                body_primary {
                  html
                  text
                }
                body_secondary {
                  html
                  text
                }
              }
              primary {
                section_id
                heading_primary
                offset_background_colour
              }
            }
            ... on PrismicPagesDataBodyLeadText {
              id
              slice_type
              primary {
                section_id
                heading_primary
                body_primary {
                  html
                }
                background_colour
                text_colour
              }
            }
            ... on PrismicPagesDataBodyTextAndImages {
              id
              slice_type
              primary {
                section_id
              }
              items {
                background_colour
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 0.86)
                    }
                  }
                }
                imageMobile: image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 0.95)
                    }
                  }
                }
                heading_primary
                body_primary {
                  html
                }
                body_secondary {
                  html
                  text
                }
                read_more_heading
              }
            }
            ... on PrismicPagesDataBodyFaqHeadingAndAccordion {
              id
              slice_type
              items {
                question_text {
                  html
                }
                question_title {
                  text
                }
              }
              primary {
                faq_heading
              }
            }
            ... on PrismicPagesDataBodyComparisonTable {
              id
              slice_type
              items {
                protein_one
                protein_two
                protein_three
                protein_four
                protein_five
                rubisco
                title
              }
              primary {
                protein_one_heading
                protein_two_heading
                protein_three_heading
                protein_four_heading
                protein_five_heading
                heading1 {
                  text
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(PagesTemplate)
