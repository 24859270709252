import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import MenuItem from '../MobileMenu/MenuItem'

const MobileMenu = ({ location, resumeScroll, menuItems, closeMobileMenu }) => {
  let active = 'mobile-menu-item--active'

  return (
    <nav className="mobile-menu__inner">
      <AniLink
        fade
        to={`/`}
        onClick={resumeScroll}
        duration={1}
        className={'mobile-menu-item'}
        activeClassName={active}
      >
        Home
      </AniLink>
      {menuItems.map((item, index) => {
        let menuItem = item.node.data
        return (
          <MenuItem
            key={index}
            item={menuItem}
            resumeScroll={resumeScroll}
            location={location}
            closeMobileMenu={closeMobileMenu}
          />
        )
      })}
    </nav>
  )
}

export default MobileMenu
