import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import { Autoplay } from 'swiper'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import ArrowRight from '../SVG/ArrowRight'

const CarouselButton = ({ direction, handleClick, buttonClasses }) => {
  return (
    <button
      onClick={() => {
        handleClick()
      }}
      className={`${direction} rounded-full w-10 md:w-12 h-10 md:h-12 flex items-center justify-center transition-colors duration-300 pointer-events-auto focus:outline-none hover:bg-opacity-75 ${buttonClasses}`}
    >
      <ArrowRight
        className={`stroke-current h-2 ${
          direction === 'previous' ? 'transform rotate-180' : ''
        }`}
      />
      <span className="sr-only">
        {direction === 'previous' ? 'Previous' : 'Next'} Slide
      </span>
    </button>
  )
}

const Carousel = ({ images, buttonClasses }) => {
  const carousel = useRef(null)

  const nextSlide = () => {
    carousel.current.swiper.slideNext()
  }

  const previousSlide = () => {
    carousel.current.swiper.slidePrev()
  }

  return (
    <div className="relative">
      <Swiper
        className="carousel"
        ref={carousel}
        slidesPerView={1}
        enabled={images.length > 1}
        spaceBetween={0}
        loop={true}
        speed={600}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {images.map((item, index) => {
          let imageData = {}
          if (item.image != null) {
            imageData = {
              image: getImage(item.image.localFile),
              alt: item.alt,
            }
          }

          if (item.image.localFile === null) {
            return null
          }

          return (
            <SwiperSlide key={index}>
              <GatsbyImage
                image={imageData.image}
                alt={imageData.alt}
                loading="lazy"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      {images.length > 1 && (
        <div className="absolute inset-0 z-10 flex items-center justify-between pointer-events-none p-4 sm:p-8">
          <CarouselButton
            buttonClasses={buttonClasses}
            direction="previous"
            handleClick={previousSlide}
          />
          <CarouselButton
            buttonClasses={buttonClasses}
            direction="next"
            handleClick={nextSlide}
          />
        </div>
      )}
    </div>
  )
}

export default Carousel
