import React from 'react'

function TwitterIcon() {
  return (
    <svg
      className="h-full w-full"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m8.034 8.448 5.405 7.228L8 21.552h1.224l4.762-5.145 3.848 5.145H22l-5.71-7.634 5.064-5.47h-1.225l-4.385 4.738L12.2 8.448H8.034Zm1.8.902h1.914l8.452 11.3h-1.914L9.834 9.35Z"
        fill="#092A33"
      />
      <path stroke="#092A33" d="M.5.5h29v29H.5z" />
    </svg>
  )
}

export default TwitterIcon
