import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Observer from 'react-intersection-observer'

import ArrowRight from '../SVG/ArrowRight'
import Logo from '../SVG/Logo'
import IconLinkedIn from '../SVG/IconLinkedIn'
import Animation from '../../components/Animation'
import animationMobileData from '../../json/footerSimplified.json'
import animationData from '../../json/footer.json'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      animationOneHasPlayed: false,
      animationOneInView: false,
    }
  }

  render() {
    const { location } = this.props
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            prismicGlobal {
              data {
                footer_heading
                postal_address {
                  html
                }
                contact_name
                contact_email
                contact_heading
                contact_phone
                linkedin_url
                hubspot_form_url
                privacy_policy_url
              }
            }
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <footer
            className={`bg-green text-white${
              location.pathname === '/' || location.pathname === ''
                ? ' hidden'
                : ''
            }`}
          >
            <div className="pt-14 lg:pt-24 mx-6 lg:mx-10 lg:px-1/24">
              <Fade ssrReveal>
                <div className="mb-12 lg:mb-16 lg:flex text-sm lg:text-base">
                  <div className="lg:w-2/5 xl:w-1/2">
                    <div className="hidden lg:block w-32">
                      <Logo className="text-cream fill-current" />
                    </div>
                  </div>
                  <div className="lg:w-3/5 xl:w-1/2 leading-relaxed">
                    <div className="lg:-ml-1/11 lg:flex">
                      <div className="lg:w-5/11 lg:pl-1/11">
                        {data.prismicGlobal.data.postal_address.html && (
                          <div
                            className="mb-12 lg:mb-0"
                            dangerouslySetInnerHTML={{
                              __html:
                                data.prismicGlobal.data.postal_address.html,
                            }}
                          />
                        )}
                      </div>
                      <div className="lg:w-5/11 lg:pl-1/11">
                        {data.prismicGlobal.data.contact_heading && (
                          <h5 className="font-medium">
                            {data.prismicGlobal.data.contact_heading}
                          </h5>
                        )}
                        {data.prismicGlobal.data.contact_name && (
                          <div>{data.prismicGlobal.data.contact_name}</div>
                        )}
                        {data.prismicGlobal.data.contact_email && (
                          <div>
                            <a
                              className="inline-block"
                              href={`mailto:${data.prismicGlobal.data.contact_email}`}
                            >
                              {data.prismicGlobal.data.contact_email}
                            </a>
                          </div>
                        )}
                        {data.prismicGlobal.data.contact_phone && (
                          <div>
                            <a
                              className="inline-block"
                              href={`tel:${data.prismicGlobal.data.contact_phone.replace(
                                /\s/g,
                                ''
                              )}`}
                            >
                              {data.prismicGlobal.data.contact_phone}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-16 lg:flex justify-end text-xs">
                  <div className="lg:w-3/5 xl:w-1/2">
                    <div className="lg:-ml-1/11 lg:flex lg:items-end">
                      <div className="lg:w-5/11 lg:pl-1/11 mb-12 lg:mb-0">
                        {data.prismicGlobal.data.linkedin_url && (
                          <div className="w-6 h-6">
                            <a
                              className="block"
                              href={data.prismicGlobal.data.linkedin_url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <IconLinkedIn />
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="lg:w-5/11 lg:pl-1/11">
                        {data.prismicGlobal.data.hubspot_form_url && (
                          <a
                            className="text-base link-svg flex items-center flex-shrink-0 leading-none"
                            href={data.prismicGlobal.data.hubspot_form_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="block">Subscribe for updates</span>
                            <ArrowRight className="ml-6 w-5 stroke-current" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-16 lg:flex justify-end text-xs text-cream">
                  <div className="lg:w-3/5 xl:w-1/2">
                    <div className="lg:-ml-1/11 lg:flex">
                      <div className="lg:w-5/11 lg:pl-1/11">
                        {data.prismicGlobal.data.privacy_policy_url && (
                          <a
                            className="text-xs leading-tightest inline-block mb-2 lg:mb-0"
                            href={data.prismicGlobal.data.privacy_policy_url}
                          >
                            Privacy Policy
                          </a>
                        )}
                      </div>
                      <div className="lg:w-5/11 lg:pl-1/11">
                        <small className="text-xs leading-tightest">
                          &copy; {new Date().getFullYear()}{' '}
                          {data.site.siteMetadata.title}, All Rights Reserved
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <Fade ssrReveal>
              <div className="mx-6 lg:mx-10 lg:px-1/24 text-cream">
                <Observer
                  as="div"
                  threshold={0.1}
                  onChange={inView => {
                    if (inView === true) {
                      setTimeout(() => {
                        this.setState({
                          animationOneInView: true,
                          animationOneHasPlayed: true,
                        })
                      }, 250)
                    } else {
                      this.setState({
                        animationOneInView: false,
                      })
                    }
                  }}
                >
                  <Animation
                    animationData={animationMobileData}
                    animationInView={this.state.animationOneInView}
                    animationHasPlayed={this.state.animationOneHasPlayed}
                    className="lg:hidden pb-10"
                  />
                  <Animation
                    animationData={animationData}
                    animationInView={this.state.animationOneInView}
                    animationHasPlayed={this.state.animationOneHasPlayed}
                    className="hidden lg:block pb-10"
                  />
                </Observer>
              </div>
            </Fade>
          </footer>
        )}
      />
    )
  }
}

export default Footer
