import React from 'react'

function CrossIcon() {
  return (
    <svg viewBox="0 0 24 24" className="h-full w-full">
      <rect width="24" height="24" rx="12" fill="#F8F4E0" fill-opacity="0.2" />
      <path
        d="M17 7L7 17M7 7L17 17"
        stroke="#F8F4E0"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default CrossIcon
