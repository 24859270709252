import React from 'react'

const Cross = ({ className }) => (
  <svg
    className={`${className ? className : ''} stroke-current`}
    viewBox="0 0 17 17"
  >
    <g fill="none" strokeLinecap="square">
      <path d="M8.5,0.544 L8.5,16" />
      <path d="M8.5,0 L8.5,16" transform="rotate(90 8.5 8)" />
    </g>
  </svg>
)

export default Cross
