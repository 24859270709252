import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

import AnchorLink from '../AnchorLink'
import ArrowDown from '../SVG/ArrowDown'
import ArrowRight from '../SVG/ArrowRight'
import Carousel from '../Carousel'

const Hero = ({ data, headerTheme, location }) => {
  let headerClasses
  let headerTextContainerClasses
  let leadClasses

  if (headerTheme === 'Brown') {
    headerClasses = 'bg-brown text-cream'
  } else if (headerTheme === 'Green') {
    headerClasses = 'bg-green text-cream'
  } else {
    headerClasses = 'bg-cream text-green'
  }

  if (location.pathname === '/' || location.pathname === '') {
    leadClasses = 'text-lg'
    headerTextContainerClasses = 'lg:w-9/11'
  } else {
    leadClasses = 'lg:text-lg xl:text-2xl'
    headerTextContainerClasses = 'lg:pr-1/4'
  }
  return (
    <div className={`fullscreen__inner flex items-center ${headerClasses}`}>
      <div className="w-full mx-6 lg:mx-10 lg:px-1/24">
        <div className="lg:flex items-center justify-between">
          {data.images.length > 0 && data.images[0].image.localFile && (
            <Fade ssrReveal delay={500}>
              <div className="w-full lg:w-1/2 lg:order-last pb-14 lg:pb-0">
                <Carousel images={data.images} buttonClasses={headerClasses} />
              </div>
            </Fade>
          )}
          <Fade ssrReveal delay={250}>
            <div className="lg:w-1/2 lg:order-1 lg:flex flex-col justify-center">
              <div
                className={`mb-5 lg:mb-10 relative z-20 ${headerTextContainerClasses}`}
              >
                {data.subheading && (
                  <h3 className="text-sm lg:text-lg mb-4 font-medium">
                    {data.subheading}
                  </h3>
                )}
                {data.heading && (
                  <h1 className="text-4xl lg:text-6xl leading-tightest mb-5 lg:mb-10">
                    {data.heading}
                  </h1>
                )}
                {data.lead.html && (
                  <div
                    className={`rte pr-1/6 lg:pr-0 font-medium ${leadClasses}`}
                    dangerouslySetInnerHTML={{
                      __html: data.lead.html,
                    }}
                  />
                )}
              </div>
              {data.button_link && data.button_text ? (
                <Fade ssrReveal delay={750}>
                  <Link
                    className="border border-green text-green link-group__target-container link-group__target-link font-medium text-base link-svg inline-block group-hover:opacity-50"
                    to={`${data.button_link.url}/`}
                  >
                    <span className="flex items-center px-4 py-3">
                      {data.button_text}
                      <ArrowRight className="ml-6 w-5 h-5 stroke-current" />
                    </span>
                  </Link>
                </Fade>
              ) : (
                <div className="relative z-10">
                  <AnchorLink
                    className="cursor-pointer -m-2 p-2 inline-block"
                    href="#main"
                  >
                    <Fade top distance="20px" delay={750}>
                      <ArrowDown />
                    </Fade>
                  </AnchorLink>
                </div>
              )}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Hero
