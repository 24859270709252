import React from 'react'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { getBackgroundClass, getTextClass } from '../../utils/classes'

const FlexibleContentPartners = ({ slice, index }) => {
  const { primary, items } = slice
  const { background_colour, text_colour } = slice.primary
  const sectionId = primary.section_id
    ? primary.section_id
    : `partners-${index}`

  return (
    <div
      id={sectionId}
      className={`lg:px-1/24 ${getBackgroundClass(
        background_colour
      )} ${getTextClass(text_colour)}`}
    >
      <div className="mx-6 lg:mx-10 py-16 lg:py-24 grid lg:grid-cols-2 lg:items-center">
        <div>
          <Fade ssrReveal delay={250}>
            {primary.heading_primary && (
              <div className="mb-6">
                <h3 className="text-3xl lg:text-5xl leading-none">
                  {primary.heading_primary}
                </h3>
              </div>
            )}
            {primary.body_primary.html && (
              <div
                className="rte lg:text-lg"
                dangerouslySetInnerHTML={{
                  __html: primary.body_primary.html,
                }}
              />
            )}
          </Fade>
        </div>
        {items.length > 0 && (
          <div className="lg:pl-2/11 grid grid-cols-2 gap-4 mt-12 lg:mt-0">
            {items.map((item, index) => {
              let imageData = {}
              if (item.logo != null) {
                imageData = {
                  image: getImage(item.logo.localFile),
                  alt: item.alt,
                }
              }
              return (
                <Fade key={index} ssrReveal delay={500 * (index + 1)}>
                  <div>
                    <div className="h-24 lg:h-36 flex items-center justify-center overflow-hidden">
                      <GatsbyImage
                        className="h-full"
                        image={imageData.image}
                        alt={imageData.alt}
                        loading="lazy"
                        objectFit="contain"
                      />
                    </div>
                  </div>
                </Fade>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default FlexibleContentPartners
