import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../Header'
import Footer from '../Footer'
import CookieConsent from "react-cookie-consent";

import '../../styles/index.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuIsOpen: false,
    }
  }

  noScroll = () => {
    document.documentElement.classList.add('no-scroll')
    document.body.classList.add('no-scroll')
  }

  resumeScroll = () => {
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.remove('no-scroll')
  }

  resizeEvent = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    if (window.innerWidth >= 990) {
      this.setState({
        mobileMenuIsOpen: false,
      })
      this.resumeScroll()
    }
  }

  componentDidMount = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', this.resizeEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEvent)
  }

  toggleMobileMenu = () => {
    this.setState({
      mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
    })

    if (this.state.mobileMenuIsOpen) {
      this.resumeScroll()
    } else {
      this.noScroll()
    }
  }

  // used for mobile anchor links
  closeMobileMenu = () => {
    this.setState({
      mobileMenuIsOpen: false,
    })

    if (this.state.mobileMenuIsOpen) {
      this.resumeScroll()
    } else {
      this.noScroll()
    }
  }

  render() {
    const { children, location, headerTheme } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            allPrismicMenuItem(sort: { fields: data___order }) {
              edges {
                node {
                  data {
                    body {
                      ... on PrismicMenuItemDataBodyAnchorLink {
                        id
                        slice_type
                        primary {
                          anchor_id
                          link_heading
                        }
                      }
                      ... on PrismicMenuItemDataBodyPageLink {
                        id
                        primary {
                          link_heading
                          link_primary {
                            uid
                          }
                        }
                        slice_type
                      }
                    }
                    heading
                    order
                    link {
                      uid
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div
            className={`font-sans text-green text-base antialiased leading-normal${location.pathname !== '/join' ||
              location.pathname !== 'join' ||
              location.pathname !== '/newsroom' ||
              location.pathname !== 'newsroom'
              ? ' fullscreen bg-cream'
              : ''
              }`}
          >
            <Header
              toggleMobileMenu={this.toggleMobileMenu}
              closeMobileMenu={this.closeMobileMenu}
              resumeScroll={this.resumeScroll}
              mobileMenuIsOpen={this.state.mobileMenuIsOpen}
              location={location}
              menuItems={data.allPrismicMenuItem.edges}
              headerTheme={headerTheme}
            />
            {children}
            <Footer location={location} />
            <CookieConsent
              location='bottom'
              style={
                {
                  color: '#F9F4E1',
                  background: '#000'
                }
              }
              buttonStyle={
                {
                  color: '#000',
                  background: '#F9F4E1',
                  borderRadius: '2px'
                }
              }
              buttonText='I understand'
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
