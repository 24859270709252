import React from 'react'

const ArrowDown = () => {
  return (
    <svg
      width="8"
      className="stroke-current"
      height="21"
      viewBox="0 0 8 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M8 16.574l-4 3.889-4-3.889" />
        <path d="M3.93 20.132V1.27" strokeLinecap="square" />
      </g>
    </svg>
  )
}

export default ArrowDown
