exports.linkResolver = doc => {
  switch (doc.type) {
    case 'pages': {
      if (doc.uid === 'homepage') {
        return '/'
      } else {
        return `/${doc.uid}`
      }
    }
    case 'news_article': {
      return `/newsroom/${doc.uid}`
    }

    default: {
      return `/${doc.uid}`
    }
  }
}
