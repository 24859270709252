import React from 'react'

function TickIcon() {
  return (
    <svg className="w-full h-full" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="12" fill="#F8F4E0" />
      <path
        d="M7 12.5548L10.5503 16.2105L17 7.78943"
        stroke="#092A33"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default TickIcon
