import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import Cross from '../SVG/Cross'
import { Helmet } from 'react-helmet'

const FlexibleContentHeadingAndAccordion = ({ slice }) => {
  const { primary, items } = slice

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map(item => ({
      '@type': 'Question',
      name: item.question_title.text,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.question_text.text,
      },
    })),
  }

  return (
    <div className="bg-brown">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="lg:flex mx-6 lg:mx-10 lg:px-1/24 py-24">
        <Fade ssrReveal delay={250}>
          <div className="lg:w-1/2 mb-12 lg:mb-0">
            <h3 className="text-4xl md:text-5xl font-medium text-cream">
              {primary.faq_heading}
            </h3>
          </div>
          <div className="lg:w-1/2">
            {items.map((item, index) => (
              <FAQAccordion
                key={index}
                item={item}
                isLast={index === items.length - 1}
              />
            ))}
          </div>
        </Fade>
      </div>
    </div>
  )
}

const FAQAccordion = ({ item, isLast }) => {
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)

  return (
    <div onClick={() => setReadMoreIsOpen(!readMoreIsOpen)}>
      <div
        className={`flex justify-between border-t items-center py-8 ${
          isLast && !readMoreIsOpen ? 'border-b' : ''
        } border-cream cursor-pointer`}
      >
        <div>
          {item.question_title.text && (
            <h3 className="text-lg md:text-xl text-cream">
              {item.question_title.text}
            </h3>
          )}
        </div>
        <Cross
          className={`w-4 h-4 cross mb-1 text-pink ${
            readMoreIsOpen ? ' cross--rotate' : ''
          }`}
        />
      </div>
      {readMoreIsOpen && item.question_text.html && (
        <div
          className={`rte pb-5 text-cream ${
            isLast && readMoreIsOpen ? 'border-b' : ''
          } border-cream`}
          dangerouslySetInnerHTML={{ __html: item.question_text.html }}
        />
      )}
    </div>
  )
}

export default FlexibleContentHeadingAndAccordion
