import React from 'react'
import TickIcon from '../SVG/TickIcon'
import CrossIcon from '../SVG/CrossIcon'
import ArrowRight from '../SVG/ArrowRight'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import clsx from 'clsx'

const FlexibleContentComparisonTable = ({ slice, index }) => {
  const { primary, items } = slice

  let imageData = {}
  if (primary.image != null) {
    imageData = {
      image: getImage(primary.image.localFile),
      alt: primary.image.alt,
    }
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Row',
        name: item.title,
        additionalProperty: [
          {
            '@type': 'PropertyValue',
            name: 'Rubisco',
            value: item.rubisco ? 'Yes' : 'No',
          },
          {
            '@type': 'PropertyValue',
            name: 'Protein One',
            value: item.protein_one ? 'Yes' : 'No',
          },
          {
            '@type': 'PropertyValue',
            name: 'Protein Two',
            value: item.protein_two ? 'Yes' : 'No',
          },
          {
            '@type': 'PropertyValue',
            name: 'Protein Three',
            value: item.protein_three ? 'Yes' : 'No',
          },
          {
            '@type': 'PropertyValue',
            name: 'Protein Four',
            value: item.protein_four ? 'Yes' : 'No',
          },
          {
            '@type': 'PropertyValue',
            name: 'Protein Five',
            value: item.protein_five ? 'Yes' : 'No',
          },
        ],
      },
    })),
  }

  return (
    <div className="bg-green py-24 px-6 lg:px-10">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="max-w-4xl mx-auto">
        {primary.heading1 && (
          <h2 className="text-3xl md:text-5xl font-medium text-cream text-center">
            {primary.heading1.text}
          </h2>
        )}
        <div class="overflow-x-scroll">
          <div className="relative overflow-x-scroll">
            <table
              className="w-full mt-24 table-fixed border-separate border-b border-cream"
              style={{ borderSpacing: 'unset' }}
            >
              <thead>
                <tr className="text-sm lg:text-xl h-16">
                  <th className="text-cream font-bold w-24 sm:w-20 text-left sticky left-0 bg-green z-20">
                    Protein Type
                  </th>
                  <th className="bg-cream text-center  px-4 lg:px-0 left-24 font-medium w-20 sm:w-20 sticky sm:left-0 z-10">
                    Rubisco
                  </th>
                  <th className="bg-cream text-center px-4 lg:px-0 font-medium w-18 sm:w-20">
                    {primary.protein_one_heading}
                  </th>
                  <th className="bg-cream text-center px-4 lg:px-0 font-medium w-18 sm:w-20">
                    {primary.protein_two_heading}
                  </th>
                  <th className="bg-cream text-center px-4 lg:px-0 font-medium w-18 sm:w-20">
                    {primary.protein_three_heading}
                  </th>
                  <th className="bg-cream text-center px-4 lg:px-0 font-medium w-18 sm:w-20">
                    {primary.protein_four_heading}
                  </th>
                  <th className="bg-cream text-center px-4 lg:px-0 font-medium w-18 sm:w-20">
                    {primary.protein_five_heading}
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={item.id || index}>
                    <td
                      className={clsx(
                        'text-xs lg:text-base text-cream font-medium w-20 sticky left-0 bg-green border-b border-cream',
                        { 'border-t border-cream border-b': index === 0 }
                      )}
                    >
                      {item.title}
                    </td>
                    <td
                      className={clsx(
                        ' border-b flex border-green justify-center items-center h-16 lg:h-20 sticky left-24 sm:left-0 bg-cream',
                        { 'border-t': index === 0 }
                      )}
                    >
                      {item.rubisco === true ? (
                        <div
                          className="w-4 h-4 md:h-6 md:w-6"
                          style={{ filter: 'invert(1)' }}
                        >
                          <TickIcon />
                        </div>
                      ) : (
                        <div className="w-4 h-4 md:h-6 md:w-6">
                          <CrossIcon />
                        </div>
                      )}
                    </td>
                    <td className="h-16 border-t border-cream">
                      {item.protein_one === true ? (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <TickIcon />
                        </div>
                      ) : (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <CrossIcon />
                        </div>
                      )}
                    </td>
                    <td className="h-16 border-t border-cream">
                      {item.protein_two === true ? (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <TickIcon />
                        </div>
                      ) : (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <CrossIcon />
                        </div>
                      )}
                    </td>
                    <td className="h-16 border-t border-cream">
                      {item.protein_three === true ? (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <TickIcon />
                        </div>
                      ) : (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <CrossIcon />
                        </div>
                      )}
                    </td>
                    <td className="h-16 border-t border-cream">
                      {item.protein_four === true ? (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <TickIcon />
                        </div>
                      ) : (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <CrossIcon />
                        </div>
                      )}
                    </td>
                    <td className="h-16 border-t border-cream">
                      {item.protein_five === true ? (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <TickIcon />
                        </div>
                      ) : (
                        <div className="mx-auto w-4 h-4 md:h-6 md:w-6">
                          <CrossIcon />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div class="md:hidden flex text-cream items-center space-x-4 justify-center pt-8">
            <p class="text-xs">Scroll to view</p>
            <ArrowRight className="stroke-current h-2" />
          </div>
        </div>

        <div className="mt-24">
          <GatsbyImage
            image={imageData.image}
            alt={imageData.alt}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  )
}

export default FlexibleContentComparisonTable
