import React from 'react'
import Fade from 'react-reveal/Fade'

import { getBackgroundClass, getTextClass } from '../../utils/classes'

const FlexibleContentBody = ({ slice, index }) => {
  const { primary } = slice
  const { background_colour, text_colour } = slice.primary
  const sectionId = primary.section_id ? primary.section_id : `body-${index}`

  return (
    <div
      id={sectionId}
      className={`${getBackgroundClass(background_colour)} ${getTextClass(
        text_colour
      )}`}
    >
      <div className="mx-10 py-40 lg:py-48 flex items-center justify-center">
        {primary.body_primary.html && (
          <Fade ssrReveal delay={250}>
            <div
              className="rte lg:w-1/2 text-lg lg:text-2xl font-medium"
              dangerouslySetInnerHTML={{
                __html: primary.body_primary.html,
              }}
            />
          </Fade>
        )}
      </div>
    </div>
  )
}

export default FlexibleContentBody
