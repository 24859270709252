import React from 'react'

const ArrowRight = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 8"
    >
      <path fill="none" d="M16.1 0L20 4l-3.9 4" />
      <path d="M19.6 4.1H.8" fill="none" strokeLinecap="square" />
    </svg>
  )
}

export default ArrowRight
