import React from 'react'

function LinkedinIcon() {
  return (
    <svg
      className="h-full w-full"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.679 20.987H9.187v-8.01h2.492v8.01Zm-1.26-9.08c-.776 0-1.419-.67-1.419-1.474 0-.777.643-1.42 1.42-1.42.803 0 1.446.643 1.446 1.42 0 .804-.643 1.473-1.446 1.473Zm10.554 9.08H18.51v-3.884c0-.938-.027-2.116-1.313-2.116-1.285 0-1.473.99-1.473 2.035v3.965h-2.49v-8.01h2.383v1.099h.027c.348-.616 1.152-1.286 2.357-1.286 2.518 0 3 1.66 3 3.804v4.393h-.027Z"
        fill="#092A33"
      />
      <path stroke="#092A33" d="M.5.5h29v29H.5z" />
    </svg>
  )
}

export default LinkedinIcon
