import React from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import {
  getBackgroundClass,
  getTextClass,
  getBorderClass,
} from '../../utils/classes'

import ArrowRight from '../SVG/ArrowRight'

const renderLinkText = item => {
  switch (item.link.link_type) {
    case 'Document':
      return (
        <Link className="link-group text-2xl mb-6" to={`/${item.link.uid}/`}>
          <h4>{item.link_text}</h4>
        </Link>
      )

    default:
      return (
        <a
          className={`link-group text-2xl ${
            item.link.link_type === 'Web' ? 'mb-6' : 'mr-6 '
          }`}
          href={item.link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>{item.link_text}</h4>
        </a>
      )
  }
}
const renderLink = item => {
  const classes =
    'link-group__target-container link-group__target-link text-base link-svg flex items-center flex-shrink-0 group-hover:opacity-50'

  let linkHeading

  if (item.link.link_type === 'Media') {
    linkHeading = 'Download'
  } else {
    linkHeading = item.link_heading ? item.link_heading : 'More Info'
  }
  switch (item.link.link_type) {
    case 'Document':
      return (
        <Link className={classes} to={`/${item.link.uid}/`}>
          <span>{item.link_heading ? item.link_heading : 'More Info'}</span>
          <ArrowRight className="ml-6 w-5 stroke-current" />
        </Link>
      )
    default:
      return (
        <a
          className={classes}
          href={item.link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{linkHeading}</span>
          <ArrowRight className="ml-6 w-5 stroke-current" />
        </a>
      )
  }
}

const FlexibleContentTextWithExternalLinks = ({ slice, index }) => {
  const { items, primary } = slice
  const sectionId = primary.section_id
    ? primary.section_id
    : `text-with-external-links-${index}`

  return (
    <div className="lg:flex" id={sectionId}>
      <div className="bg-white lg:w-1/2">
        <div className="mx-6 lg:mr-0 lg:ml-10 lg:pl-1/12 lg:flex py-16 lg:py-24">
          <div className="lg:w-9/11">
            <Fade ssrReveal delay={250}>
              {primary.heading_primary && (
                <h3 className="text-3xl lg:text-5xl leading-none mb-8 lg:mb-16">
                  {primary.heading_primary}
                </h3>
              )}
            </Fade>
            <Fade ssrReveal delay={500}>
              {primary.lead_primary.html && (
                <div
                  className="rte lg:text-3xl leading-tight mb-8 lg:mb-12"
                  dangerouslySetInnerHTML={{
                    __html: primary.lead_primary.html,
                  }}
                />
              )}
            </Fade>
            <Fade ssrReveal delay={750}>
              <div>
                {primary.body_primary.html && (
                  <div
                    className="rte lg:text-lg"
                    dangerouslySetInnerHTML={{
                      __html: primary.body_primary.html,
                    }}
                  />
                )}
                {(primary.link?.url || primary.link?.uid) && (
                  <div className="mt-10">
                    {primary.link.link_type === 'Web' ? (
                      <a
                        className="text-base link-svg flex items-center flex-shrink-0 group-hover:opacity-50"
                        href={primary.link.url}
                        target={primary.link.target}
                        rel="noopener noreferrer"
                      >
                        <span>
                          {primary.link_heading
                            ? primary.link_heading
                            : 'More Info'}
                        </span>
                        <ArrowRight className="ml-6 w-5 stroke-current" />
                      </a>
                    ) : (
                      <Link
                        className=" text-base link-svg flex items-center flex-shrink-0 group-hover:opacity-50"
                        to={`/${primary.link.uid}/`}
                      >
                        <span>
                          {primary.link_heading
                            ? primary.link_heading
                            : 'More Info'}
                        </span>
                        <ArrowRight className="ml-6 w-5 stroke-current" />
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </Fade>
          </div>
          <div className="lg:w-2/11" />
        </div>
      </div>
      <div
        className={`${getBackgroundClass(
          primary.background_colour
        )} ${getTextClass(primary.text_colour)} lg:w-1/2`}
      >
        <div className="mx-6 lg:ml-0 lg:mr-10 lg:pr-1/12 lg:flex h-full items-center py-16 lg:py-24">
          <div className="lg:w-2/11" />
          <div className="lg:w-9/11 h-full">
            <Fade ssrReveal delay={1000}>
              {primary.heading_secondary && (
                <h4 className="text-3xl mb-10 lg:mb-16">
                  {primary.heading_secondary}
                </h4>
              )}
            </Fade>
            <Fade ssrReveal delay={1250}>
              {items.length > 0 ? (
                <div
                  className={`border-t -mx-6 lg:mx-0 ${getBorderClass(
                    primary.text_colour
                  )}`}
                >
                  {items.map((item, index) => {
                    return (
                      <Fade
                        key={index}
                        ssrReveal
                        delay={1000 + 250 * (index + 1)}
                      >
                        <div
                          className={`flex border-b py-5 px-6 lg:px-0 ${getBorderClass(
                            primary.text_colour
                          )} ${
                            item.link.link_type === 'Media'
                              ? 'justify-between'
                              : 'flex-col'
                          }`}
                        >
                          {item.link_text && renderLinkText(item)}
                          {item.link.url && renderLink(item)}
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              ) : (
                primary.no_links_message && (
                  <div className="rte lg:text-lg">
                    {primary.no_links_message}
                  </div>
                )
              )}
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlexibleContentTextWithExternalLinks
