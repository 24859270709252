import React from 'react'

const RightChevron = () => {
  return (
    <svg
      width="11"
      className="stroke-current"
      height="19"
      viewBox="0 0 11 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.648 1.5l7.778 8-7.778 8"
        // stroke="#FF8188"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default RightChevron
