import React from 'react'
import { SliceZone } from '@prismicio/react'

import FlexibleContentBody from './FlexibleContentBody'
import FlexibleContentLeadText from './FlexibleContentLeadText'
import FlexibleContentHeadingAndBody from './FlexibleContentHeadingAndBody'
import FlexibleContentTextWithExternalLinks from './FlexibleContentTextWithExternalLinks'
import FlexibleContentImageAndAccordion from './FlexibleContentImageAndAccordion'
import FlexibleContentTextAndImages from './FlexibleContentTextAndImages'
import FlexibleContentPartners from './FlexibleContentPartners'
import FlexibleContentHeadingAndAccordion from './FlexibleContentFaqs'
import FlexibleContentComparisonTable from './FlexibleContentComparisonTable'

const FlexibleContent = ({ data }) => {
  return (
    <SliceZone
      slices={data}
      components={{
        body: FlexibleContentBody,
        lead_text: FlexibleContentLeadText,
        heading_and_body: FlexibleContentHeadingAndBody,
        text_with_external_links: FlexibleContentTextWithExternalLinks,
        image_and_accordion: FlexibleContentImageAndAccordion,
        text_and_images: FlexibleContentTextAndImages,
        partners: FlexibleContentPartners,
        faq_heading_and_accordion: FlexibleContentHeadingAndAccordion,
        comparison_table: FlexibleContentComparisonTable,
      }}
    />
  )
}

export default FlexibleContent
