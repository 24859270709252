import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { getBackgroundClass } from '../../utils/classes'

import Cross from '../SVG/Cross'

const FlexibleContentImageAndAccordion = ({ slice, index }) => {
  const { primary, items } = slice

  const sectionId = primary.section_id
    ? primary.section_id
    : `image-and-accordion-${index}`
  return (
    <div id={sectionId}>
      <div
        className={`${getBackgroundClass(primary.offset_background_colour)}`}
      >
        <div className="bg-white lg:w-1/2">
          <div className="mx-6 lg:mr-0 lg:ml-10 lg:pl-1/12">
            {primary.heading_primary && (
              <Fade ssrReveal delay={250}>
                <h2 className="pt-24 lg:pt-18 pb-10 lg:pb-0 text-5xl font-medium">
                  {primary.heading_primary}
                </h2>
              </Fade>
            )}
          </div>
        </div>
      </div>
      <div className="lg:bg-cream relative">
        <div className="bg-white lg:w-1/2 absolute h-full" />
        <div className="mx-6 lg:mx-10 lg:py-24 lg:px-1/24 relative z-10">
          <div className="flex flex-wrap">
            {items.map((item, index) => {
              return (
                <AccordionWithImage
                  key={`${index}-${item.body_primary}`}
                  item={item}
                  index={index}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const AccordionWithImage = ({ item, index }) => {
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)

  let imageData = {}
  if (item.image != null) {
    imageData = {
      image: getImage(item.image.localFile),
      alt: item.alt ? item.alt : item.body_primary.text,
    }
  }

  return (
    <Fade ssrReveal delay={250}>
      <div
        className={`lg:w-1/2 lg:text-lg${
          index >= 2
            ? ' bg-cream lg:bg-transparent -mx-6 lg:mx-0 px-6 lg:px-0'
            : ''
        }`}
      >
        <div className={index % 2 === 0 ? 'lg:pr-2/11' : 'lg:pl-2/11'}>
          <div className="lg:border-b border-grey mb-24">
            <div className="-mx-6 lg:mx-0 lg:w-7/9 mb-12">
              <GatsbyImage
                image={imageData.image}
                alt={imageData.alt}
                loading="lazy"
              />
            </div>
            <div>
              {item.body_primary.html && (
                <div
                  className="rte mb-8"
                  dangerouslySetInnerHTML={{
                    __html: item.body_primary.html,
                  }}
                />
              )}
              {readMoreIsOpen && item.body_secondary.html && (
                <div
                  className="rte mb-8"
                  dangerouslySetInnerHTML={{
                    __html: item.body_secondary.html,
                  }}
                />
              )}

              {item.body_secondary.text !== '' && (
                <button
                  className="link-opacity focus:outline-none lg:mb-12 font-medium leading-none flex items-center w-full"
                  onClick={() => {
                    setReadMoreIsOpen(!readMoreIsOpen)
                  }}
                >
                  {readMoreIsOpen ? 'Read Less' : 'Read More'}
                  <Cross
                    className={`w-4 h-4 ml-5 cross mb-1${
                      readMoreIsOpen ? ' cross--rotate' : ''
                    }`}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default FlexibleContentImageAndAccordion
