import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/Layout'
import Seo from '../components/SEO'
import AnchorLink from '../components/AnchorLink'
import ArrowDown from '../components/SVG/ArrowDown'
import RightChevron from '../components/SVG/RightChevron'
import CallToAction from '../components/CallToAction'
import FlexibleContentTextWithExternalLinks from '../components/FlexibleContent/FlexibleContentTextWithExternalLinks'

const NewsroomPage = ({ data, location }) => {
  const page = data.prismicMedia.data
  const externalArticles = data.allPrismicExternalArticle.edges
  const newsArticles = data.allPrismicNewsArticle.edges

  const media = {
    primary: {
      section_id: 'media-kit',
      heading_primary: page.heading_primary,
      heading_secondary: page.heading_secondary,
      lead_primary: page.lead_primary,
      body_primary: page.body_primary,
      background_colour: 'White',
      link: page.link,
      link_heading: page.link_heading,
    },
    items: page.assets,
  }
  return (
    <Layout location={location}>
      <Seo
        title={page.meta_title && page.meta_title}
        description={page.meta_description && page.meta_description}
        location={location}
        canonicalUrl={page.canonical_url.url && page.canonical_url.url}
      />
      <div className="bg-cream">
        <div className="mx-6 lg:mx-10 pt-10 lg:pt-40 lg:px-1/24">
          <div className="lg:flex">
            <div className="lg:w-14/22">
              <Fade ssrReveal delay={250}>
                {page.heading && (
                  <h1 className="w-1/3 text-3xl lg:text-6xl leading-none relative z-20 bg-cream lg:pb-10">
                    {page.heading}
                  </h1>
                )}
              </Fade>
              <div className="relative z-10 hidden lg:block">
                <AnchorLink
                  className="inline-block cursor-pointer -m-2 p-2"
                  href="#main"
                >
                  <Fade top distance="20px" delay={750}>
                    <ArrowDown />
                  </Fade>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>

        <main id="main">
          {externalArticles.length !== 0 && (
            <Fade ssrReveal delay={250}>
              <div
                id="featured-stories"
                className="flex items-start overflow-x-scroll smooth-scroll lg:-ml-8 lg:offset-left pb-8 -mb-8 pt-5 lg:pt-20"
              >
                {externalArticles.map((item, index) => {
                  let imageData = {}
                  if (item.node.data.image != null) {
                    imageData = {
                      image: getImage(item.node.data.image.localFile),
                      alt: item.node.data.heading,
                    }
                  }

                  return (
                    <a
                      className="w-3/4 md:w-1/2 lg:w-5/24 flex-shrink-0 ml-6 lg:ml-8 hover:opacity-100 group"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.node.data.link.url}
                      key={index}
                    >
                      <div className="mb-6 group-hover:opacity-75 transition-opacity duration-300 ease-linear">
                        <GatsbyImage
                          className="w-full"
                          image={imageData.image}
                          alt={imageData.alt}
                          loading="lazy"
                        />
                      </div>
                      <h6 className="text-pink uppercase text-xs font-bold mb-2 tracking-wide">
                        {item.node.data.source}
                      </h6>
                      <h4 className="text-2xl leading-tight">
                        {item.node.data.heading}
                      </h4>
                    </a>
                  )
                })}
                <div className="pr-6 lg:pr-8 self-stretch" />
              </div>
            </Fade>
          )}

          <div id="our-news" className="mx-6 lg:mx-10 lg:px-1/24 py-20">
            {data.prismicMedia.data.media_heading && (
              <div className="lg:w-14/22">
                <Fade ssrReveal delay={250}>
                  <h2 className="w-1/3 text-3xl lg:text-6xl leading-none pb-5 lg:pb-10">
                    {data.prismicMedia.data.media_heading}
                  </h2>
                </Fade>
              </div>
            )}
            <div className="-mx-6 lg:mx-0">
              {newsArticles.map((article, index) => {
                let item = article.node.data
                let uid = article.node.uid

                return (
                  <Fade key={index} ssrReveal delay={250 + 100 * (index + 1)}>
                    <div
                      className={`border-t border-green-50 pt-8 pb-6${
                        index === newsArticles.length - 1 ? ' border-b' : ''
                      }`}
                    >
                      <div className="px-6 lg:px-0 lg:flex justify-between items-center">
                        {item.category && (
                          <div className="lg:hidden text-xs mb-2">
                            {item.category}
                          </div>
                        )}
                        {item.heading && (
                          <Link
                            className="inline-block mr-8 hover:opacity-100 focus:opacity-100 link-group"
                            to={`/newsroom/${uid}/`}
                          >
                            <h5 className="inline-block text-2xl leading-snug lg:text-3xl font-medium pb-3 lg:pb-0">
                              {item.heading}
                            </h5>
                            {item.summary.text !== '' && (
                              <div
                                className="rte mb-3 lg:mb-0 lg:mt-2"
                                dangerouslySetInnerHTML={{
                                  __html: item.summary.html,
                                }}
                              ></div>
                            )}
                          </Link>
                        )}
                        <div className="link-group__target-container lg:w-8/22  flex-shrink-0 flex justify-between items-center font-medium">
                          {item.category && (
                            <div className="hidden lg:block">
                              {item.category}
                            </div>
                          )}
                          <Link
                            className="flex link-svg link-group__target-link"
                            to={
                              item.category === 'Blog post'
                                ? `/blog/${uid}/`
                                : `/newsroom/${uid}/`
                            }
                          >
                            <div className="mr-4 leading-tight pt-px">
                              Read more
                            </div>
                            <RightChevron />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Fade>
                )
              })}
            </div>
          </div>

          <FlexibleContentTextWithExternalLinks slice={media} />
        </main>
        <CallToAction />
      </div>
    </Layout>
  )
}

export const pageJoinQuery = graphql`
  query {
    prismicMedia {
      _previewable
      data {
        heading
        media_heading
        link_heading
        link {
          uid
          link_type
          url
          target
        }
        lead_primary {
          html
        }
        assets {
          link {
            uid
            link_type
            url
            target
          }
          link_text
        }
        heading_primary
        heading_secondary
        body_primary {
          html
        }
        meta_title
        meta_description
        canonical_url {
          url
        }
      }
    }
    allPrismicNewsArticle(sort: { fields: data___post_date, order: DESC }) {
      edges {
        node {
          _previewable
          uid
          data {
            category
            heading
            post_date
            summary {
              html
              text
            }
          }
        }
      }
    }
    allPrismicExternalArticle(
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          _previewable
          data {
            heading
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.71)
                }
              }
            }
            source
            link {
              url
            }
          }
          last_publication_date
        }
      }
    }
  }
`

NewsroomPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withPrismicPreview(NewsroomPage)
