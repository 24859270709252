import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Submenu from '../Menu/Submenu'

const MenuItem = ({ location, item, resumeScroll, closeMobileMenu }) => {
  let active = 'mobile-menu-item--active'

  return (
    <div className="mt-7 w-full">
      <AniLink
        fade
        to={`/${item.link.uid}/`}
        onClick={resumeScroll}
        duration={1}
        className="mobile-menu-item"
        activeClassName={active}
        partiallyActive={true}
      >
        <div>{item.heading}</div>
      </AniLink>
      {item.body.length > 0 && (
        <div className="mt-2 pt-4 border-t border-cream-50 w-full">
          <Submenu
            item={item}
            showSubmenu={true}
            location={location}
            closeMobileMenu={closeMobileMenu}
          />
        </div>
      )}
    </div>
  )
}

export default MenuItem
