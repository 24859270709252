import React from 'react'

import MenuItem from './MenuItem'

const Menu = ({
  location,
  className,
  resumeScroll,
  borderColor,
  divideColor,
  bgColor,
  menuItems,
  headerTheme,
}) => {
  let textColor
  let textColor50

  if (headerTheme === 'Brown' || headerTheme === 'Green') {
    textColor = 'menu-item--cream'
    textColor50 = 'menu-item--cream menu-item--50'
  } else {
    textColor = 'menu-item--green'
    textColor50 = 'menu-item--green menu-item--50'
  }

  return (
    <nav
      className={`relative min-h-full pb-12 lg:pb-0 lg:divide-x ${
        divideColor ? divideColor : ''
      } ${className ? className : ''} border-l ${
        borderColor ? borderColor : ''
      }`}
    >
      {menuItems.map((item, index) => {
        let menuItem = item.node.data
        return (
          <MenuItem
            key={index}
            item={menuItem}
            resumeScroll={resumeScroll}
            location={location}
            textColor={textColor}
            textColor50={textColor50}
            bgColor={bgColor}
            borderColor={borderColor}
            divideColor={divideColor}
          />
        )
      })}
    </nav>
  )
}

export default Menu
