import React, { useState } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Submenu from './Submenu'

const MenuItem = ({
  className,
  location,
  item,
  resumeScroll,
  textColor,
  textColor50,
  bgColor,
  borderColor,
  divideColor,
}) => {
  const [showSubmenu, setShowSubmenu] = useState(false)
  function handleMouseEnter() {
    setShowSubmenu(true)
  }
  function handleMouseLeave() {
    setShowSubmenu(false)
  }
  let active = 'menu-item--active'

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`relative w-1/4 ${className ? ' ' + className : ''}`}
    >
      <AniLink
        fade
        to={`/${item.link.uid}/`}
        onClick={resumeScroll}
        duration={1}
        className={`menu-item ${
          location.pathname !== '/' ? textColor50 : textColor
        }`}
        activeClassName={active}
        partiallyActive={true}
      >
        <div>{item.heading}</div>
      </AniLink>
      {item.body.length > 0 && (
        <Submenu
          item={item}
          showSubmenu={showSubmenu}
          bgColor={bgColor}
          borderColor={borderColor}
          divideColor={divideColor}
          location={location}
          className={location.pathname !== '/' ? textColor50 : textColor}
        />
      )}
    </div>
  )
}

export default MenuItem
