import React from 'react'

function ClockIcon() {
  return (
    <svg fill="none" viewBox="0 0 17 15">
      <path
        stroke="#092A33"
        stroke-opacity="0.85"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
        d="M15.2128 6.79743L13.7919 8.21875L12.3702 6.79743M13.7919 7.49996C13.7919 3.96757 10.9283 1.104 7.39596 1.104C3.86357 1.104 1 3.96757 1 7.49996C1 11.0323 3.86357 13.8959 7.39596 13.8959C9.40518 13.8959 11.198 12.9694 12.3706 11.5204M7.39596 3.94665V7.49996L9.52794 8.92128"
      />
    </svg>
  )
}

export default ClockIcon
